.accordion{
    background-color: #222222;
    border-radius: 10px;
    overflow: hidden;
}
.accordion:not(:last-child){
    margin-bottom: 10px;
}
.accordion__title{
    color: #00D8FF;
    width: 100%;
    border: none;
    outline: none;
    padding: 18px;
    background: none;
    cursor: pointer;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
}
.accordion__content{
    padding: 0 18px;
    background-color: white;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease;
}
.accordion__content p{
    padding: 5px 0;
}
