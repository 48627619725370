.App {
  background-color: #89A068;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-title{
  font-family: 'Montserrat', serif;
  font-weight: 500;
  font-size: clamp(2.5rem, 1.5rem + 5vw, 7.5rem);
  line-height: 1.22;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #173F1E;
  text-align: center;
  margin: 0 0 10px;
}
